/* Footer and Footer Component CSS */
.footer {
  padding-top: 7%;
}

/* Mailchimp Email Sign-up Form */
.mail-form {
  position: relative;
  background-color: rgb(241, 192, 100);
  padding-top: 7%;
  padding-bottom: 20%;
  text-align: center; /* centers the input field*/
}

input[type=email] {
  border: none;
  border-bottom: 3px solid rgb(173, 166, 166);
  width: 50%;
  background-color: rgb(255, 233, 199);
}

input[type=submit] {
  background-color: rgb(94, 192, 64);
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
}

/* SMS Sign-up Button & Btn Sizing */
.sms-message-icon .btn-lg {
  position: fixed;
  z-index: 1;
  right: 0px;
  bottom: 25px;
}