.App {
  text-align: center;
}

/* Styles for screens with a width of 768 pixels or larger (tablets and desktops) */
@media screen and (min-width: 768px) {
  .container-fluid { width: 768px; 
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
   }
}

/* Styles for screens with a width of 992 pixels or larger (large desktops) */
@media screen and (min-width: 992px) {
  .container-fluid { width: 992px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    }
}

/* Styles for screens with a width of 576 pixels or smaller (mobile devices) */
@media screen and (max-width: 376px) {
  .container-fluid { width: 376px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
   }
}