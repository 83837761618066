.player-wrapper {
  position: relative;
  margin-bottom: 10px;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
   
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}