/*** TO BE DEPRECATED ***/
/* Common CSS for icon containers */
.social-icons,
.donations-icons {
    height: 15vmin;
    transition: background-color 0.3s ease; /* Add transition for smooth color change */
}

/* Common hover effect */
.social-icon:hover,
.donation-icon:hover {
    opacity: 0.7;
    color: #3d5655;
    transform: scale(1.05); /* Add scale transformation for a slight zoom effect */
}

.social-grid, .donations-grid {
    padding-top: 2%;
    padding-right: 5%;
    display: inline-block;
}

/* Styles for screens with a width of 768 pixels or larger (tablets and desktops) */
@media screen and (min-width: 768px) {
    .social-icons,.donations-icons {
        height: 10vmin;
    }
    
}
  
/* Styles for screens with a width of 992 pixels or larger (large desktops) */
@media screen and (min-width: 992px) {
    .social-icons,.donations-icons {
        height: 10vmin;
    }
    
}

/* Styles for screens with a width of 576 pixels or smaller (mobile devices) */
@media screen and (max-width: 576px) {
    .donations-icons {
        height: 10vmin;
    }
}