/* Music Icons CSS */
.music-grid-buttons .list-group-item {
    border-radius: 30px; /* Adjust the border-radius to change button roundness */
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 0.9); /* Adjust the last value (alpha) to control the opacity */
    transition: background-color 0.3s ease; /* Add transition for smooth color change */
}

.music-grid-buttons {
    display:block;
    flex-direction: column;
    align-items:center;
}

.music-grid-buttons .list-group-item:hover {
    opacity: 0.7;
    background-color: rgba(240, 240, 240, 1); /* Adjust the last value (alpha) to control the opacity */
    transform: scale(1.05); /* Add scale transformation for a slight zoom effect */
}

/* Styles for screens with a width of 768 pixels or larger (tablets and desktops) */
@media screen and (min-width: 768px) {
    
}
  
/* Styles for screens with a width of 992 pixels or larger (large desktops) */
@media screen and (min-width: 992px) {

}

/* Styles for screens with a width of 576 pixels or smaller (mobile devices) */
@media screen and (max-width: 576px) {

}