.button-container {
    padding-top: 2%;
    padding-right: 5%;
    display: inline-block;
}
  .btn-group {
    display: flex;
    justify-content: center;
  }

  .btn-img {
    height: 15vmin;
    transition: background-color 0.3s ease; /* Add transition for smooth color change */
  }
  
  /* Adjust styles based on screen size */
  @media screen and (max-width: 768px) {
    /* CSS adjustments for screens smaller than 768px */
  }
  
  @media screen and (max-width: 576px) {
    /* CSS adjustments for screens smaller than 576px */
  }
  