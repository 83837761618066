.album-art-container {
  position: relative;
  min-height: 30vmin;
}

.album-art {
  height: 85vmin;
  padding: 2%;
  border-radius: 20px;
}

.album-art::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  border-radius: 20px;
}

/* Styles for screens with a width of 768 pixels or larger (tablets and desktops) */
@media screen and (min-width: 768px) {
  .container-fluid { width: 768px; }
  .album-art {
    height: 50vmin;
    width: 50vmin;
    padding: 2%;
  }
}
/* Styles for screens with a width of 992 pixels or larger (large desktops) */
@media screen and (min-width: 992px) {
  .container-fluid { width: 992px; }
  .album-art {
    height: 75vmin;
    width: 75vmin;
    padding: 2%;
  }
}
/* Styles for screens with a width of 576 pixels or smaller (mobile devices) */
@media screen and (max-width: 576px) {}


